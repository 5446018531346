import type { MetaFunction } from "@remix-run/node";
import { Outlet } from "@remix-run/react";
import { LoginInner, LoginOuter, LoginTitle } from "~/components/routes/_auth";
import { createTitle } from "~/util/title";

export const meta: MetaFunction = () => {
  return [{ title: createTitle("認証") }];
};

export default function Layout() {
  return (
    <LoginOuter>
      <LoginInner>
        <LoginTitle>契約管理システム</LoginTitle>
        <Outlet />
      </LoginInner>
    </LoginOuter>
  );
}
